import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "Components/misc/Headings.js";
import { SectionDescription } from "Components/misc/Typography.js";
import {  ContentWithPaddingXl } from "Components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  width: 100%;
  margin: 10px 0;
`;

const Line = styled.div`
  width:30px;
  height: 1px;
  width:50px;
  background-color: grey; // Adjust color as needed
`;

const Text = styled.h2`
  padding: 0 5px;
  color: #ff5464; // Adjust color as needed
  font-size: 20px; // Adjust size as needed
  white-space: nowrap;
`;
const Heading = tw(SectionHeading)`w-full text-black text-5xl font-bold`;
const Description = tw(SectionDescription)`w-full text-center text-gray-600`;


const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div`mb-8`;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-100 hover:bg-gray-200 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);

const Container = tw.div`relative bg-white mt-12 mb-12`;


const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed text-gray-700`);


export default ({
  subheading = "FAQ",
  heading = "Frequently Asked Questions",
  description = "Find answers to common questions about our web services. If you can't find what you're looking for, don't hesitate to contact our support team.",
  faqs = [
    {
      question: "What services does MilaajBrandset offer?",
      answer:
        "MilaajBrandset offers a comprehensive range of web services including web design, web development, e-commerce solutions, digital marketing, API development, and UI/UX design. We specialize in creating custom digital solutions tailored to each client's unique needs and business goals."
    },
    {
      question: "How long does it typically take to complete a web development project?",
      answer:
        "The timeline for a web development project can vary depending on its complexity and scope. A simple website might take 4-6 weeks, while a more complex web application could take 3-6 months. We provide a detailed timeline estimate at the beginning of each project and keep our clients updated throughout the development process."
    },
    {
      question: "Do you offer ongoing support and maintenance after the website is launched?",
      answer:
        "Yes, we offer comprehensive ongoing support and maintenance packages to ensure your website continues to perform optimally after launch. This includes regular updates, security checks, performance optimization, and technical support. We believe in building long-term relationships with our clients and providing continuous value."
    },
    {
      question: "Can you help with migrating my existing website to a new platform?",
      answer:
        "Absolutely! We have extensive experience in website migrations. Our team ensures a smooth transition of your content, databases, and functionality to the new platform while minimizing downtime. We also take this opportunity to improve your website's performance, security, and user experience."
    },
    {
      question: "What is your approach to ensuring website security?",
      answer:
        "We take website security very seriously. Our approach includes implementing SSL certificates, regular security audits, using secure coding practices, and setting up firewalls and malware scanning. We also keep all software and plugins updated, use strong authentication methods, and educate our clients on best practices for maintaining website security."
    },
    {
      question: "Do you provide SEO services along with web development?",
      answer:
        "Yes, we offer comprehensive SEO services as part of our digital marketing package. This includes on-page optimization, content strategy, keyword research, technical SEO, and performance optimization. Our goal is to improve your website's search engine rankings and drive organic traffic to your site."
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && 
            <SubheadingWrapper>
              <Line />
                <Text>{subheading}</Text>
              <Line />
              </SubheadingWrapper>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
    </Container>
  );
};