import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "Components/misc/Headings";
import { SectionDescription } from "Components/misc/Typography";
import { Container, ContentWithPaddingXl } from "Components/misc/Layouts";
import ArrowRightIcon  from "images/arrow-right-icon.avif";
import { Link } from "react-router-dom";

const Heading = tw(SectionHeading)`text-black`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-wrap justify-center max-w-screen-2xl mx-auto md:py-24 font-poppins`}
`;

const Column = styled.div`
  ${tw`flex flex-col items-center w-full md:w-1/3`} 
`;

const Card = styled.div`
  ${tw`flex flex-col 
  mx-auto 
  items-center 
  justify-center
  border-2 border-primary-500 
  rounded-4xl
  mt-12 
  mx-8
  shadow-cxl relative`} 
  width: 405px; 
  height: 399px;

  .imageContainer {
    ${tw`flex-shrink-0 relative my-8`} /* Ensure the image doesn't shrink and set position relative */

    .circleBackground {
      ${tw`flex items-center justify-center rounded-full`} /* Make the div circular */
      width: 80px; /* Set width for the circle */
      height: 80px; /* Set height for the circle */
      background-color: #f0f0f0; /* Set your desired background color */
      position: absolute; /* Position absolute to align with the image */
      top: -10px; /* Adjust as necessary to position behind the image */
      left: -10px; /* Adjust as necessary to position behind the image */
    }

    img {
      ${tw`w-[60px] h-[60px]`} /* Set the desired image size */
      position: relative; /* Ensure image is positioned above the background */
      z-index: 1; /* Ensure the image is above the circle */
    }
  }

  .textContainer {
    ${tw`mt-2 no-underline  `}
  }

  .title {
    ${tw`mt-0 font-bold text-xl leading-none text-black no-underline`}
    text-decoration: none !important;
  }

  .description {
    ${tw`mt-3 font-medium text-secondary-100 text-center w-[320px] leading-loose no-underline`}
  }

  &:hover {
    ${tw`transform scale-105`} /* Scale on hover */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2); /* Add shadow effect on hover */
    transition: all 0.3s ease; /* Smooth transition */
  }
`;


const StyledLink = styled(Link)`
  ${tw`no-underline`}
`;

const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  text-center
  width: 100%;
  margin: 10px 0;
`;

const Line = styled.div`
  width:30px;
  height: 1px;
  width:50px;
  background-color: grey; // Adjust color as needed
`;

const Text = styled.h2`
  padding: 0 5px;
  color: #ff5464; // Adjust color as needed
  font-size: 20px; // Adjust size as needed
  white-space: nowrap;
`;


export default ({
  cards = [],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <SubheadingWrapper><Line /><Text>{subheading}</Text><Line /></SubheadingWrapper>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <StyledLink to={card.url}>
                <Card>
                  <span className="imageContainer" css={imageContainerCss}>
                  <div className="circleBackground"></div> {/* Circle background */}
                    <img src={card.imageSrc} alt="" css={imageCss} />
                  </span>
                  <span className="title">{card.title}</span>
                  <p className="description">{card.description}</p>
                  {linkText && (
                    <span className="link">
                      <span>{linkText}</span>
                      <ArrowRightIcon className="icon" />
                    </span>
                  )}
                </Card>
              </StyledLink>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};