import React, { useContext, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import ServiceCard from "./ServiceCard"; // Import the updated ServiceCard
import "./Details.css"; // Import custom CSS for styling
import DigitalMarketingIcon from "images/DigitalMarketingIcon.png"
import BrandIdentityIcon from "images/BrandIdentityIcon.png"
import WebDevelopmentIcon from "images/WebDevelopmentIcon.png"
import SeoIcon from "images/SeoIcon.png"
import EcommerceIcon from "images/EcommerceIcon.png"
import UiUxIcon from "images/UIUXicon.png"
import SocialMediaManagementIcon from "images/SocialMediaManagementIcon.png"
import PaidMediaIcon from "images/PaidMediaCampaign.png"
import VidoAdProductionIcon from "images/VideoAdProductionIcon.png"


// Mock services data
// Mock services data
const services = [
  {
    title: "Brand Identity Development",
    imgSrc: BrandIdentityIcon,
  },
  {
    title: "Web Design and Development",
    imgSrc: WebDevelopmentIcon,
  },
  {
    title: "Social Media Management",
    imgSrc: SocialMediaManagementIcon,
  },
  {
    title: "Paid Media Campaigns",
    imgSrc: PaidMediaIcon,
  },
  {
    title: "UI/UX Design",
    imgSrc: UiUxIcon,
  },
  {
    title: "Digital Marketing",
    imgSrc: DigitalMarketingIcon,
  },
  {
    title: "E-commerce Solutions",
    imgSrc: EcommerceIcon,
  },
  {
    title: "Search Engine Optimization",
    imgSrc: SeoIcon,
  },
  {
    title: "Video Ad Production",
    imgSrc: VidoAdProductionIcon,
  },
  {
    title:'Other services'
  },
];


const Details = () => {
  const { details, setDetails, next } = useContext(MultiStepFormContext);
  const [selectedService, setSelectedService] = useState(details.selectedService || "");
  const [errors, setErrors] = useState({});
  console.log(selectedService);
  

  const handleSubmit = () => {
    if (!selectedService) {
      setErrors({ selectedService: "Please select a service." });
    } else {
      setErrors({});
      setDetails({ selectedService });
      next();
    }
  };

  return (
    <div className="details-container">
      <h3 className="details-heading text-center text-xl font-semibold mb-6">
      Let's create something amazing together! What type of service are you looking for?
      </h3>
      <div className="details-grid grid grid-cols-1 md:grid-cols-2 gap-6">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            imgSrc={service.imgSrc}
            isSelected={selectedService === service.title}
            onClick={() => setSelectedService(service.title)}
          />
        ))}
      </div>
      {errors.selectedService && (
        <p className="details-error text-red-500 mt-4">{errors.selectedService}</p>
      )}
      <div className="details-actions flex justify-center mt-6">
        <button
          className="details-button bg-blue-500 text-white px-6 py-2 rounded-full shadow-lg hover:bg-blue-600 transition-all"
          onClick={handleSubmit}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Details;
