// import React, { useEffect, useRef } from "react";
// import styled from "styled-components";
// import tw from "twin.macro";
// import { gsap } from "gsap";
// import { Link } from "react-router-dom";
// import { SectionHeading } from "Components/misc/Headings";

// import defaultCardImage from "../../images/shield-icon.svg";
// import SupportIconImage from "../../images/apiDevelopment.svg";
// import ShieldIconImage from "../../images/digitalMarketing.svg";
// import CustomizeIconImage from "../../images/webDevelopment.svg";
// import FastIconImage from "../../images/e-commerce.svg";
// import ReliableIconImage from "../../images/seo.svg";
// import SimpleIconImage from "../../images/ui-ux.svg";

// const Container = styled.div`
//   ${tw`relative`}
//   padding: 50px 0;
//   overflow: hidden;
//   margin-bottom: 150px;

//   &:before {
//     content: '';
//     ${tw`absolute inset-0 bg-white`}
//     z-index: -2; /* Places it behind everything else */
//   }
// `;

// const BackgroundEllipse = styled.div`
//   ${tw`absolute opacity-25 rounded-full`}
//   width: 100vw;
//   height: 100vw;
//   pointer-events: none;
//   background: radial-gradient(circle, rgba(128,0,128,0.7) 0%, rgba(128,0,128,0.4) 70%);
//   @media (max-width: 768px) {
//     width: 200vw;
//     height: 200vw;
//   }
//   z-index: -1; /* Place it above the background but behind the content */
// `;

// const TopLeftEllipse = styled(BackgroundEllipse)`
//   top: -50%;
//   left: -50%;
// `;

// const BottomRightEllipse = styled(BackgroundEllipse)`
//   bottom: -50%;
//   right: -50%;
// `;

// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-2xl mx-auto py-20 md:py-24 font-poppins`}
//   z-index: 1; /* Ensure it has a higher z-index than the ellipses */
// `;

// const Heading = tw(SectionHeading)`w-full`;

// const Column = styled.div`
//   ${tw`px-6 z-10`}
// `;

// const Card = styled.div`
//   ${tw`flex flex-col mx-auto items-center bg-white px-6 py-12 border-2 border-primary-500 rounded-3xl mt-12 shadow-cxl relative`}
//   width: 335px;
//   height: 380px;
//   z-index:2;
//   .imageContainer {
//     ${tw`flex-shrink-0`}
//     img {
//       ${tw`w-[150px] h-[150px] mb-6`}
//     }
//   }

//   .textContainer {
//     ${tw`mt-2 text-center w-[290px] no-underline`}
//   }

//   .title {
//     ${tw`mt-0 font-bold text-xl leading-none text-customPurple no-underline`}
//     text-decoration: none !important;
//   }

//   .description {
//     ${tw`mt-3 font-medium text-secondary-100 leading-relaxed text-sm no-underline`}
//   }
// `;

// export default () => {
//   const cards = [
//     {
//       imageSrc: ShieldIconImage,
//       title: "Digital Marketing",
//       description:
//         "Boost your online presence with our comprehensive digital marketing services, including social media management, email campaigns, and PPC advertising.",
//       link: "/digital-marketing",
//     },
//     {
//       imageSrc: SupportIconImage,
//       title: "API Development",
//       description:
//         "Streamline your business processes with our robust API development services, ensuring seamless integration and high performance.",
//       link: "/api-development",
//     },
//     {
//       imageSrc: CustomizeIconImage,
//       title: "Web Development",
//       description:
//         "Create a stunning and responsive website with our expert web development team, specializing in modern technologies and user-centered design.",
//       link: "/web-development",
//     },
//     {
//       imageSrc: ReliableIconImage,
//       title: "SEO",
//       description:
//         "Improve your search engine rankings and drive organic traffic to your site with our effective SEO strategies tailored to your business needs.",
//       link: "/seo",
//     },
//     {
//       imageSrc: FastIconImage,
//       title: "E-Commerce",
//       description:
//         "Launch a successful e-commerce store with our complete solutions, including payment gateway integration, product management, and security features.",
//       link: "/e-commerce",
//     },
//     {
//       imageSrc: SimpleIconImage,
//       title: "UI/UX Design",
//       description:
//         "Enhance user satisfaction with our intuitive UI/UX design services, focusing on creating engaging and user-friendly interfaces.",
//       link: "/ui-ux-design",
//     },
//   ];

//   const cardRefs = useRef([]);

//   useEffect(() => {
//     cardRefs.current.forEach((card) => {
//       card.addEventListener("mouseenter", () => {
//         gsap.to(card, {
//           scale: 1.05,
//           duration: 0.3,
//           ease: "power1.out",
//           boxShadow: "0 15px 30px rgba(0, 0, 0, 0.2)",
//         });
//       });

//       card.addEventListener("mouseleave", () => {
//         gsap.to(card, {
//           scale: 1,
//           duration: 0.3,
//           ease: "power1.out",
//           boxShadow: "none",
//         });
//       });
//     });

//     return () => {
//       cardRefs.current.forEach((card) => {
//         card.removeEventListener("mouseenter", () => {});
//         card.removeEventListener("mouseleave", () => {});
//       });
//     };
//   }, []);

//   return (
//     <Container>
//       <TopLeftEllipse />
//       <BottomRightEllipse />
//       <ThreeColumnContainer>
//         <Heading>
//           Our Professional <span tw="text-customPurple">Services</span>
//         </Heading>
//         {cards.map((card, i) => (
//           <Column key={i}>
//             <Link style={{ textDecoration: "none" }} to={card.link}>
//               <Card ref={(el) => (cardRefs.current[i] = el)}>
//                 <span className="imageContainer">
//                   <img
//                     src={card.imageSrc || defaultCardImage}
//                     alt={`${card.title} Icon`}
//                   />
//                 </span>
//                 <span className="textContainer">
//                   <span className="title">{card.title}</span>
//                   <p className="description">{card.description}</p>
//                 </span>
//               </Card>
//             </Link>
//           </Column>
//         ))}
//       </ThreeColumnContainer>
//     </Container>
//   );
// };



import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { SectionHeading } from "Components/misc/Headings";

import defaultCardImage from "../../images/shield-icon.svg";
import DigitalMarketingImage from "images/DigitalMarketingIcon.png"
import BrandIdentityImage from "images/BrandIdentityIcon.png"
import WebDesignImage from "images/WebDevelopmentIcon.png"
import SeoImage from "images/SeoIcon.png"
import ECommerceImage from "images/EcommerceIcon.png"
import UiUxImage from "images/UIUXicon.png"
import SocialMediaImage from "images/SocialMediaManagementIcon.png"
import PaidMediaImage from "images/PaidMediaCampaign.png"
import VideoAdImage from "images/VideoAdProductionIcon.png"

// Styled Components
const Container = styled.div`
  ${tw`relative`}
  padding: 50px 0;
  overflow: hidden;
  margin-bottom: 150px;

  &:before {
    content: '';
    ${tw`absolute inset-0 bg-white`}
    z-index: -2; /* Places it behind everything else */
  }
`;

const BackgroundEllipse = styled.div`
  ${tw`absolute opacity-25 rounded-full`}
  width: 100vw;
  height: 100vw;
  pointer-events: none;
background: radial-gradient(circle, rgba(255, 84, 100, 0.7) 0%, rgba(255, 84, 100, 0.4) 70%);
  @media (max-width: 768px) {
    width: 200vw;
    height: 200vw;
  }
  z-index: -1; /* Place it above the background but behind the content */
`;

const TopLeftEllipse = styled(BackgroundEllipse)`
  top: -50%;
  left: -50%;
`;

const BottomRightEllipse = styled(BackgroundEllipse)`
  bottom: -50%;
  right: -50%;
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:flex-row md:justify-center flex-wrap max-w-screen-2xl mx-auto  md:py-24 font-poppins`}
  z-index: 1; /* Ensure it has a higher z-index than the ellipses */
`;

const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`px-6 z-10`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto items-center bg-white py-3 lg:px-6 md:px-6 py-12 border-2 border-primary-500 rounded-3xl mt-12 shadow-cxl relative`}
  max-width: 335px;
  height: 430px;
  z-index: 2;

  .imageContainer {
    ${tw`flex-shrink-0`}
   
  }

  .textContainer {
    ${tw`mt-2 text-center w-full py-2 px-4`} /* Adjust width and add padding */
  }

  .title {
    ${tw`mt-0 font-bold text-lg sm:text-xl leading-none text-customPurple`}
  }

  .description {
    ${tw`mt-3 font-medium text-secondary-100 leading-relaxed text-sm`}
  }

  @media (max-width: 360px) {
    ${tw`py-6 px-4`} /* Adjust padding for extra small screens */
    .imageContainer img {
      ${tw`w-[170px] h-[170px] mb-3`} /* Smaller image */
    }
    .title {
      ${tw`text-xl py-2`} /* Adjust title size */
    }
    .description {
      ${tw`text-sm `} /* Adjust description size */
    }
  }
`;

export default () => {
  const cards = [
    {
      imageSrc: BrandIdentityImage,
      title: "Brand Identity Development",
      description:
        "Craft a unique brand identity that resonates with your audience and sets you apart from the competition.",
      link: "/brand-identity-development",
    },
    {
      imageSrc: WebDesignImage,
      title: "Web Design and Development",
      description:
        "Build stunning, user-friendly websites tailored to your business needs and goals.",
      link: "/web-design-development",
    },
    {
      imageSrc: SocialMediaImage,
      title: "Social Media Management",
      description:
        "Grow your online presence with engaging content and strategic social media campaigns.",
      link: "/social-media-management",
    },
    {
      imageSrc: PaidMediaImage,
      title: "Paid Media Campaigns",
      description:
        "Drive traffic and conversions with data-driven ad campaigns across Google, Facebook, and more.",
      link: "/paid-media-campaigns",
    },
    {
      imageSrc: UiUxImage,
      title: "UI/UX Design",
      description:
        "Design seamless and visually appealing interfaces that enhance user experiences.",
      link: "/ui-ux-design",
    },
    {
      imageSrc: DigitalMarketingImage,
      title: "Digital Marketing",
      description:
        "Reach your target audience with integrated marketing strategies that deliver measurable results.",
      link: "/digital-marketing",
    },
    {
      imageSrc: ECommerceImage,
      title: "E-commerce Solutions",
      description:
        "Create powerful online stores that drive sales and provide an exceptional shopping experience.",
      link: "/e-commerce-solutions",
    },
    {
      imageSrc: SeoImage,
      title: "Search Engine Optimization (SEO)",
      description:
        "Boost your website’s visibility and rank higher on search engines to attract organic traffic.",
      link: "/search-engine-optimization",
    },
    {
      imageSrc: VideoAdImage,
      title: "Video Ad Production",
      description:
        "Produce captivating video ads that tell your story and engage your audience effectively.",
      link: "/video-ad-production",
    },
  ];
  

  const cardRefs = useRef([]);

  useEffect(() => {
    const handleMouseEnter = (card) => {
      gsap.to(card, {
        scale: 1.05,
        duration: 0.3,
        ease: "power1.out",
        boxShadow: "0 15px 30px rgba(0, 0, 0, 0.2)",
      });
    };

    const handleMouseLeave = (card) => {
      gsap.to(card, {
        scale: 1,
        duration: 0.3,
        ease: "power1.out",
        boxShadow: "none",
      });
    };

    cardRefs.current.forEach((card, i) => {
      if (card) {
        card.addEventListener("mouseenter", () => handleMouseEnter(card));
        card.addEventListener("mouseleave", () => handleMouseLeave(card));
      }
    });

    return () => {
      cardRefs.current.forEach((card, i) => {
        if (card) {
          card.removeEventListener("mouseenter", () => handleMouseEnter(card));
          card.removeEventListener("mouseleave", () => handleMouseLeave(card));
        }
      });
    };
  }, []);

  return (
    <Container>
      <TopLeftEllipse />
      <BottomRightEllipse />
      <ThreeColumnContainer>
        <Heading>
          Our Professional <span tw="text-customPurple">Services</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Link style={{ textDecoration: "none" }} to={card.link}>
              <Card ref={(el) => (cardRefs.current[i] = el)}>
                <span className="imageContainer">
                  <img
                    src={card.imageSrc || defaultCardImage}
                    alt={`${card.title} Icon`}
                  />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title}</span>
                  <p className="description">{card.description}</p>
                </span>
              </Card>
            </Link>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
