import React, { useContext } from "react";
import { Formik, Field } from "formik";
import MultiStepFormContext from "./MultiStepFormContext";
import "./Address.css";

const Details = () => {
  const { address, setAddress, next, prev } = useContext(MultiStepFormContext);

  return (
    <Formik
      initialValues={address}
      onSubmit={(values) => {
        console.log(values);
        setAddress(values);
        next();
      }}
      validate={(values) => {
        const errors = {};
        if (!values.name) errors.name = "Name is required";
        if (!values.email) errors.email = "Email is required";
        if (!values.phone) errors.phone = "Phone number is required";
        return errors;
      }}
    >
      {({ handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit} className="details-container">
          <h3 className="details-heading">
            Tell us a little more about yourself and we’ll get the ball rolling.
          </h3>
          <div className="form-wrapper">
            <div className={`form-item ${errors.name && touched.name && "input-error"}`}>
              <label>Name</label>
              <Field name="name" className="input-field" />
              {errors.name && touched.name && <p className="error-feedback">{errors.name}</p>}
            </div>
            <div className={`form-item ${errors.email && touched.email && "input-error"}`}>
              <label>Email</label>
              <Field name="email" type="email" className="input-field" />
              {errors.email && touched.email && <p className="error-feedback">{errors.email}</p>}
            </div>
            <div className={`form-item ${errors.phone && touched.phone && "input-error"}`}>
              <label>Phone Number</label>
              <Field name="phone" type="tel" className="input-field" />
              {errors.phone && touched.phone && <p className="error-feedback">{errors.phone}</p>}
            </div>
            <div className="form-item">
              <label>Tell us a little about the project...</label>
              <Field name="projectDetails" as="textarea" className="textarea-field" />
            </div>
            <div className="button-container">
              <button
                type="button"
                className="button back-button"
                onClick={prev}
              >
                Back
              </button>
              <button type="submit" className="button continue-button">
                Continue
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Details;
