import PropTypes from 'prop-types';
import './Details.css'; // Import the CSS file

const Confirmation = ({ confirmation }) => {
  const { icon, title, subtitle } = confirmation;

  return (
    <div className="confirmation-wrapper">
      <div className="confirmation-icon-wrapper">
        <img src={icon} alt="Confirmation Icon" className="confirmation-icon" />
      </div>
      <h1 className="confirmation-title">{title}</h1>
      <h2 className="confirmation-subtitle">{subtitle}</h2>
    </div>
  );
};

export default Confirmation;

Confirmation.propTypes = {
  confirmation: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};
