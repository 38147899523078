import React, { useState } from "react";
import { Provider } from "./MultiStepFormContext";
import Details from "./Details";
import Address from "./Address";
import Review from "./Review";
import PageHeader from "Components/headers/PageHeader";
import Confirmation from "./confirmation";
import Icon from './icon-thank-you.svg'

// Initial states
const detailsInitialState = {
  name: "",
  age: "",
  profession: ""
};

const addressInitialState = {
  address1: "",
  address2: "",
  city: ""
};

// Confirmation data
const confirmationData = {
  icon:Icon, // Replace with the path to your confirmation icon
  title: "Thank You!",
  subtitle: "Your details have been successfully submitted."
};

const renderStep = (step, props) => {
  switch (step) {
    case 0:
      return <Details />;
    case 1:
      return <Address />;
    case 2:
      return <Review />;
    case 3:
      return <Confirmation confirmation={props.confirmation} />;
    default:
      return null;
  }
};

const MultiStepForm = () => {
  const [details, setDetails] = useState(detailsInitialState);
  const [address, setAddress] = useState(addressInitialState);
  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    if (currentStep === 3) {
      setCurrentStep(0);
      setDetails(detailsInitialState);
      setAddress(addressInitialState);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const prev = () => setCurrentStep(currentStep - 1);

  return (
    <Provider
      value={{ details, setDetails, next, prev, address, setAddress }}
    >
      <PageHeader from={"Grow With Us"} />
      <main>
        {renderStep(currentStep, { confirmation: confirmationData })}
      </main>
    </Provider>
  );
};

export default MultiStepForm;
