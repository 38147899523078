import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedinIconSvg } from "images/linkedin-icon.svg";
import { ReactComponent as EmailIcon } from "images/EmailBS.svg";
import { ReactComponent as WhatsAppIcon } from "images/WhatsappBS.svg";
import { ReactComponent as PhoneIcon } from "images/PhoneBS.svg"; // Import your email icon
import LogoImage from "images/logo.png";
import { InstagramIcon, Linkedin, LinkedinIcon, LucidePhone } from "lucide-react";

const Container = tw.div`relative bg-white text-black -mb-8 -mx-8  py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FourColumns = tw.div`flex flex-wrap text-center sm:text-center justify-center sm:justify-center md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 w-full sm:w-1/2 md:w-auto mt-12`;
const ColumnHeading = tw.h5`uppercase font-bold mb-4`;
const ServicesLinkList = styled.ul`
  ${tw`mt-6 text-sm font-medium`}
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  
  @media (max-width: 640px) {
    grid-template-columns: 1fr; // Stack columns on smaller screens
  }
`;

const LinkList = styled.ul`
  ${tw`mt-6 text-sm font-medium`}
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const LinkListItem = styled.li`
  ${tw`relative`}
  &:not(:last-child)::after {
    content: "";
    ${tw`absolute bottom-0 left-0 w-full border-b border-gray-300`}
    transform: translateY(10px);
  }
`;

const Link = styled.a`
  ${tw`block pb-1 text-black transition duration-300 hover:text-customPurple hover:border-customPurple`}
  border-bottom: 2px solid transparent;
  text-decoration: none;
  &:hover {
    ${tw`border-customPurple`}
  }
`;

const Divider = tw.div`my-16 border-b-2 border-gray-200 w-full`;
const FourColRow = tw.div`flex flex-col md:flex-row items-center justify-between text-center`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-customPurple`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;
const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex justify-center`;

const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full  text-gray-900    transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-8 h-6`}
  }
`;

const ContactItem = tw.div`flex items-center justify-center mb-2`; // Centering the contact items
const ContactLink = tw.a`no-underline text-gray-900`
const ContactText = tw.p`ml-2 text-sm font-medium text-gray-900 text-center`; // Center text in the contact item
const ContactIcon = styled.div`
  ${tw`w-5 h-5`}
  svg {
    ${tw`w-full h-full`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <FourColumns>
          <Column>
            <LogoContainer>
              <LogoImg src={LogoImage} />
              <LogoText>MilaajBrandset.</LogoText>
            </LogoContainer>
          </Column>
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              <LinkListItem>
                {/* <Link href="/blogs">Blog</Link> */}
              </LinkListItem>
              <LinkListItem>
                <Link href="/faq">Faq</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="contact">Support</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="about">About Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Services</ColumnHeading>
            <ServicesLinkList>
              <LinkListItem>
                <Link href="/digital-marketing">Digital Marketing</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/api-development">API Development</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web-development">Web Development</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/e-commerce">E-Commerce</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/ui-ux-design">UI/UX Design</Link>
              </LinkListItem>
            </ServicesLinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <ContactItem>
                  <ContactIcon>
                    <PhoneIcon color="#ff5464" />
                  </ContactIcon>
                  <ContactText><ContactLink href="tel:+971 52 524 4884">+971 52 524 4884</ContactLink></ContactText>
                </ContactItem>
              </LinkListItem>
              <LinkListItem>
                <ContactItem>
                  <ContactIcon>
                    <EmailIcon />
                  </ContactIcon>
                  <ContactText><ContactLink href="mailto:support@milaajbrandset.com">support@milaajbrandset.com</ContactLink></ContactText>
                </ContactItem>
              </LinkListItem>
            </LinkList>
          </Column>
        </FourColumns>
        <Divider />
        <FourColRow>
          <CopywrightNotice>&copy; 2024 MilaajBrandset. All Rights Reserved.</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/profile.php?id=61562903405669">
              <FacebookIcon color="#ff5464" />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/milaajbrandset/">
              <InstagramIcon color="#ff5464"/>
            </SocialLink>
            <SocialLink href="https://linkedin.com">
              <LinkedinIconSvg color="#ff5464" />
            </SocialLink>
            <SocialLink href="https://linkedin.com">
              <WhatsAppIcon color="#ff5464" />
            </SocialLink>
          </SocialLinksContainer>
        </FourColRow>
      </Content>
    </Container>
  );
};
