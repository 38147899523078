import { Button, Col, Row } from "antd";
import React, { useContext } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import "./Details.css"; // Import the updated CSS file

const Review = () => {
  const { details, address, next, prev } = useContext(MultiStepFormContext);

  return (
    <div className="details__wrapper">
      <Row gutter={[16, 16]}>
        {/* Selected Service Section */}
        <Col span={24}>
          <h1>Selected Service</h1>
          <p>
            <strong>Service:</strong> {details?.selectedService || "No service selected"}
          </p>
        </Col>

        {/* Address Details Section */}
        <Col span={24}>
          <h1>Address Details</h1>
          <p>
            <strong>Name:</strong> {address?.name || "N/A"}
          </p>
          <p>
            <strong>Email:</strong> {address?.email || "N/A"}
          </p>
          <p>
            <strong>Phone:</strong> {address?.phone || "N/A"}
          </p>
          <p>
            <strong>Project Details:</strong> {address?.projectDetails || "N/A"}
          </p>
        </Col>

        {/* Additional Details Section */}

        {/* Navigation Buttons */}
        <Col span={24}>
          <div className="form__item button__items d-flex justify-content-between">
            <Button type="default" onClick={prev}>
              Back
            </Button>
            <Button type="primary" onClick={next}>
              Confirm
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Review;
