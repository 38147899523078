import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "Components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons.js";

const Container = tw.div`relative px-4 md:px-8 lg:px-16`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 md:py-16 lg:py-20`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const ImageColumn = styled(Column)((props) => [
  tw`md:w-5/12 flex-shrink-0 md:h-auto relative`,
  props.imageColumnStyle,
]);

const ImageContainer = styled.div`
  ${tw`rounded-2xl bg-cover bg-center absolute`}
  ${(props) => `background-image: url(${props.src});`}
  ${(props) => props.imageStyle}
  ${(props) => {
    const positions = props.positions || [
      "left: 0; top: 0;",
      "right: 0; top: 10%;",
      "left: 10%; bottom: 0;",
    ];
    return positions[props.index % positions.length];
  }}

  width: ${(props) => props.widths || "100%"};
  height: ${(props) => props.heights || "100%"};

  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    transform: scale(1.05);
    z-index: 10;
  }
`;

const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-12 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-12 md:order-first`
    : tw`md:ml-8 lg:ml-12 md:order-last`,
]);

const TextContent = tw.div`lg:py-8 font-poppins`;

const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

const Line = styled.div`
  height: 1px;
  width: 50px;
  background-color: grey;
`;

const Text = styled.h2`
  padding: 0 5px;
  color: #ff5464;
  font-size: 20px;
  white-space: nowrap;
`;

const Subheading = tw(
  SubheadingBase
)`text-center md:text-left text-sm md:text-base lg:text-lg`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`;

const CallToActionWrapper = styled.div`
  ${tw`flex flex-col items-start justify-center w-3/6 p-6 bg-customPurple rounded-3xl shadow-lg mt-8`}
`;

const CallToActionText = styled.p`
  ${tw`text-start text-black text-3xl font-medium mb-4`}
`;

const CallToActionButton = styled.button`
  ${tw`bg-transparent text-white text-lg font-medium border-2 py-4 px-8 rounded-lg hover:bg-white hover:cursor-pointer hover:text-customPurple hover:border-transparent transition-all duration-300`}

 
  }
`;

const Span = styled.span`
  ${tw`text-white`}
`;

export default function TwoColWithButton({
  subheading = "OUR MISSION",
  heading = <>Transforming Ideas Into Exceptional Experiences</>,
  description = "At MilaajBrandset, we deliver exceptional digital solutions tailored to your needs. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  primaryButtonText = "Contact Us",
  primaryButtonUrl = "https://milaajbrandset.com",
  imageSrcs = [
    "/path-to-headset-image.jpg",
    "/path-to-laptop-image.jpg",
    "/path-to-chart-image.jpg",
  ],
  textOnLeft = false,
  widths = [], // Default widths for each image
  heights = [], // Default heights for each image
  imageColumnStyle = ``,
  imageStyle = ``,
  imagePositions = null,
  from = "",
}) {
  return (
    <Container>
      <TwoColumn>
        {/* Image Column */}
        <ImageColumn imageColumnStyle={imageColumnStyle}>
          {imageSrcs.map((src, index) => (
            <ImageContainer
              key={index}
              src={src}
              index={index}
              widths={widths[index]}
              heights={heights[index]}
              imageStyle={imageStyle}
              positions={imagePositions}
            />
          ))}
        </ImageColumn>

        {/* Text Column */}
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SubheadingWrapper>
              <Line />
              <Text>{subheading}</Text>
              <Line />
            </SubheadingWrapper>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>

            {/* Conditional Rendering for CTA or Primary Button */}
            {from === "boost" ? (
              <CallToActionWrapper>
                <CallToActionText>
                  Ready to <Span className="text-black font-bold">elevate</Span>{" "}
                  your brand?
                </CallToActionText>
                <a href="tel:+9710505225056"><CallToActionButton>Let’s Chat</CallToActionButton></a>
              </CallToActionWrapper>
            ) : (
              <PrimaryButton as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}
