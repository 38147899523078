import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; // eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "Components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons";
import StatsIllustrationSrc from "images/stats-illustration2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import BoostBrandImage from "../../images/BoostBrandImage.png";

const Container = tw.section`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left  font-poppins`;

const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

const Line = styled.div`
  width: 30px;
  height: 1px;
  width: 50px;
  background-color: grey; // Adjust color as needed
`;

const Text = styled.h2`
  padding: 0 5px;
  color: #ff5464; // Adjust color as needed
  font-size: 20px; // Adjust size as needed
  white-space: nowrap;
`;

const Heading = tw(
  SectionHeading
)`mt-4 text-black font-custom text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-lg inline-block mx-auto md:mx-0 py-4`;

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const CallToActionWrapper = styled.div`
  ${tw`flex flex-col items-start justify-center w-3/6 p-6 bg-customPurple rounded-3xl shadow-lg mt-8`}
`;

const CallToActionText = styled.p`
  ${tw`text-start text-black text-3xl font-medium mb-4`}
`;

const CallToActionButton = styled.button`
  ${tw`bg-transparent text-white text-lg font-medium border-2 py-4 px-8 rounded-lg hover:bg-white hover:cursor-pointer hover:text-customPurple hover:border-transparent transition-all duration-300`}

 
  }
`;

const Span = styled.span`
  ${tw`text-white`}
`;

export default ({
  subheading = "Boost You Brand",
  heading = (
    <>
      Your{" "}
      <span tw="text-customPurple">
        <br />
        Brand Deserve's
      </span>{" "}
      To Shine.
    </>
  ),
  description = "With cutting-edge Social Media Management, Strategic Brand Positioning, Creative Content Creation, Video Production, Paid Media Campaigns, and more, we have everything your brand needs to shine and stand out from the competition. Let us take your brand to new heights!",
  primaryButtonText = "Get Started",
  primaryButtonUrl = "#",
  imageSrc = BoostBrandImage,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false,
}) => {
  // const defaultStatistics = [
  //   {
  //     key: "Happy Clients",
  //     value: "500+"
  //   },
  //   {
  //     key: "Projects Completed",
  //     value: "1200+"
  //   },
  //   {
  //     key: "Awards Won",
  //     value: "50+"
  //   }
  // ];

  // if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image
              imageSrc={BoostBrandImage}
              css={imageCss}
              alt="Statistics Illustration"
            />
          ) : (
            <img src={imageSrc} css={imageCss} alt="Statistics Illustration" />
          )}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && (
              <SubheadingWrapper>
                <Line />
                <Text>{subheading}</Text>
                <Line />
              </SubheadingWrapper>
            )}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {/* <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics> */}
            <CallToActionWrapper>
              <CallToActionText>
                Ready to <Span className="text-black font-bold">elevate</Span>{" "}
                your brand?
              </CallToActionText>
              <a href="https://wa.me/971505225056" target="_blank" rel="noopener noreferrer">
              <CallToActionButton>Let’s Chat</CallToActionButton>
              </a>
            </CallToActionWrapper>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
