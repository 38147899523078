import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from "Components/headers/PageHeader";
import Features from "Components/Features/ThreeColSimple";
import TeamCardGrid from "Components/Cards/ProfileThreeColGrid";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/team-illustration.svg";
import CustomerLoveIconImage from "images/customer.svg";

import About1 from "images/pages/about-1.jpg";
import About2 from "images/pages/about-2.jpg";
import About3 from "images/pages/about-3.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header from={'About Us'}/>
      <MainFeature1
        subheading={<Subheading>About MilaajBrandset</Subheading>}
        heading="Empowering Your Digital Presence"
        primaryButtonText="See Portfolio"
        primaryButtonUrl="/contact" // Add the URL for the button
        imageSrcs={[
          "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          "https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        ]}    
        description="At MilaajBrandset, we specialize in enhancing your digital presence through cutting-edge web design and development. Our team of experts is dedicated to creating innovative solutions that not only meet but exceed your expectations. We believe in the power of a strong online presence and work tirelessly to ensure your brand stands out in the digital landscape."
        textOnLeft={false} // Text on left for this one
        widths={["50%", "45%", "55%"]}
        heights={["50%", "70%", "50%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 0%; top: 10%;",
          "right: 19%; top: 20%;",
          "left: 5%; bottom: 0;",
        ]} // Custom positions for each image
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Transforming Ideas into Exceptional Experiences"
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrcs={[
          "https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          "https://images.unsplash.com/photo-1604328698692-f76ea9498e76?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          "https://images.unsplash.com/photo-1573497620053-ea5300f94f21?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",

        ]}
        description="Our vision is to transform your ideas into exceptional digital experiences. We aim to create visually stunning, user-friendly websites that engage and captivate your audience. Our commitment to excellence drives us to continuously innovate and push the boundaries of what is possible in web development."
        textOnLeft={true}
        widths={["50%", "60%", "50%"]}
        heights={["60%", "40%", "60%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 43%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]} // Custom positions for each image
      />

      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="Guiding Principles That Define Us"
        description="At MilaajBrandset, we are committed to delivering innovative solutions that drive your business forward."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "Our dedicated support team ensures your queries are addressed promptly and efficiently.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "We build strong teams of experts who collaborate to deliver outstanding results.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "Customer satisfaction is at the heart of everything we do.",
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Meet Our Team</Subheading>}
      /> */}
    </AnimationRevealPage>
  );
};
